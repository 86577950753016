import React from 'react';
import AppleLoginButton from './AppleLoginButton';
import KakaoLoginButton from './KakaoLoginButton';
import GoogleLoginButton from "./GoogleLoginButton";

const LoginPage = () => {
  return (
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <KakaoLoginButton/>
        <AppleLoginButton/>
        <GoogleLoginButton/>
      </div>
  );
};

export default LoginPage;
